<script setup lang="ts">
const props = defineProps<{
	label?: string
	info?: string
	grid?: boolean
	row?: boolean
}>();

// Info Tooltip
function getTooltipContent() {
	return `
		<span class="tooltip">
			${props.info ? `<span class="tooltip__description">${props.info}</span>` : ''}
		</span>
	`;
}
</script>

<template>
	<fieldset
		class="form-check-group"
		:class="[
			{ 'form-check-group--grid': props.grid },
			{ 'form-check-group--row': props.row },
		]"
	>
		<legend class="form-check-group__header" v-if="props.label">
			<label class="form-check-group__label">{{ props.label }}</label>
			<button
				type="button"
				class="form-control-label__info"
				v-if="props.info"
				v-pv-tooltip.top="{
					value: getTooltipContent(),
					escape: false,
				}"
			>
				<Icon name="info-empty" size="14" />
			</button>
		</legend>
		<div class="form-check-group__body">
			<slot />
		</div>
	</fieldset>
</template>
